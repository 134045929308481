
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class apply_task extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  t_all_data=[]

t_total= 0;
t_search_data = {
  name: '',
  status: '',
  res_source_unit: ''

}

rule_t_search_data = {

}

t_edit_dialog = {
  open: false,
  title: '评估任务'
}

t_edit_data = {
  id: 0,
  status_1: 0

}

rule_t_edit_data = {

}

t_edit1_dialog = {
  open: false,
  title: '邀请专家'
}

t_edit1_data = {
  id: 0,
  user_list: []

}

rule_t_edit1_data = {

}

t_edit2_dialog = {
  open: false,
  title: '指定评估员'
}

t_edit2_data = {
  id: 0,
  user_list1: 0

}

rule_t_edit2_data = {

}

t_edit3_dialog = {
  open: false,
  title: '指定撰写人'
}

t_edit3_data = {
  id: 0,
  user_list2: 0

}

rule_t_edit3_data = {

}

t_edit4_dialog = {
  open: false,
  title: '审核意见'
}

t_edit4_data = {
  id: 0,
  assess: [] as any[]

}

rule_t_edit4_data = {

}

t_edit5_dialog = {
  open: false,
  title: '撰写报告'
}

t_edit5_data = {
  id: 0,
  report: [] as any[]

}

rule_t_edit5_data = {

}

t_edit6_dialog = {
  open: false,
  title: '接纳评估意见'
}

t_edit6_data = {
  id: 0,
  evaluate_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: []

  },
  base_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      is_evaluate: 0,
      evaluator_type: 0,
      purpose: 0,
      finish_time: '',
      fee: 0,
      entrust_contact: '',
      entrust_phone: '',
      wechat: '',
      email: '',
      remark: '',
      name: '',
      code: '',
      modality: 0,
      res_source: 0,
      res_source_unit: '',
      source_unit_nature: 0,
      project_name: '',
      type: 0,
      industry_category_id: [],
      related_industry_ids: [],
      custome_related_industries: '',
      contact: '',
      phone: '',
      finish_units: [] as any[]

    }

  },
  profile_info: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      summary: [] as any[]

    }

  },
  wbs_deconstruct: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      wbs: [] as any[]

    }

  },
  techinque_level: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      core_technologies: [] as any[],
      techinque_level: {
        maturity: 0,
        maturity_reason: '',
        innovation: 0,
        innovation_reason: '',
        advanced: 0,
        advanced_reason: ''

      },
      description: '',
      question_suggest: ''

    }

  },
  bmk_analysis: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      obj1_info: '',
      obj2_info: '',
      bmk: [] as any[]

    }

  },
  promotion: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      extend: [] as any[]

    }

  },
  economic_benefit: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      economic_benefit: [] as any[]

    }

  },
  social_benefit: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      social_benefit: [] as any[]

    }

  },
  intellectual_property: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      intellectual_property: [] as any[]

    }

  },
  team: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      team_table: [] as any[],
      team_txt: [] as any[]

    }

  },
  risk: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      risk: [] as any[]

    }

  },
  input_and_output: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      end_time: '',
      facility_charge: 0,
      materials_expenses: 0,
      processing_costs: 0,
      laboratory_fee: 0,
      power_cost: 0,
      travel_expense: 0,
      conference_fee: 0,
      communication_fee: 0,
      transaction_fee: 0,
      service_fee: 0,
      consulting_fee: 0,
      administrative_fee: 0,
      incentive_fee: 0,
      rest_fee: 0,
      sales_revenue_fee: 0,
      append_fee: 0,
      total_fee: 0,
      profit_fee: 0,
      income_tax_rate_fee: 0,
      income_tax_fee: 0,
      profit_afte_tax_fee: 0,
      output_description: ''

    }

  },
  technology_novelty: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      check_organization: '',
      report: '',
      report_date: '',
      novelty_point: '',
      search_results: '',
      other_notes: ''

    }

  },
  detect_test: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      check_organization: '',
      testing_institutions: '',
      detected_name: '',
      report: '',
      report_date: '',
      search_results: '',
      other_notes: ''

    }

  },
  technical_valuation: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      valuation: [] as any[]

    }

  },
  financing_needs: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      valuation: [] as any[]

    }

  },
  files: {
    id: 0,
    menu_type: 0,
    menu: '',
    json_data: {
      files: [] as any[]

    }

  },
  res_menus: [] as any[],
  opinion_list: [] as any[],
  status_2: 0

}

rule_t_edit6_data = {

}

expertList:any[]=[]
getUserList:any[]=[]
getUserList2:any[]=[]
status_28 = new Map<any, string>([[0, '草稿'], [1, '待指派专家'], [2, '待专家评'], [3, '已完成'], [4, '待申请（专家申请评估）'], [5, '待审核'], [6, '审核不通过'], [7, '待指派组长'], [8, '待指派报告审查员'], [9, '待指派项目评估员'], [10, '待评估员评估'], [11, '待审核评估'], [12, '评估退回'], [13, '待指定报告撰写人'], [14, '待撰写报告'], [15, '待组长审核报告'], [16, '报告退回撰写'], [17, '待审查报告'], [18, '报告退回组长'], [19, '待盖章确认']])
status_1_29 = new Map<any, string>([[1, '接受'], [2, '拒绝']])
modality_31 = new Map<any, string>([[1, '新方法'], [2, '新产品'], [3, '新工艺'], [4, '新材料/配方'], [5, '新设备/工具'], [6, '软件系统'], [7, '新品种'], [8, '其他']])
res_source_32 = new Map<any, string>([[1, '纵向'], [2, '横向'], [3, '其他']])
type_35 = new Map<any, string>([[1, '应用技术'], [2, '基础理论'], [3, '软课题研究'], [4, '教育教学'], [5, '创新能力与条件建设'], [6, '技术转移与推广'], [7, '文学艺术实践'], [8, '其它']])
user_list1_55 = new Map<any, string>([])
user_list2_56 = new Map<any, string>([])
status_67 = new Map<any, string>([[6, '通过'], [5, '退回']])
is_evaluate_81 = new Map<any, string>([[0, '否'], [1, '是']])
evaluator_type_82 = new Map<any, string>([[1, '第三方评'], [2, '公开邀请评估'], [3, '自邀专家评']])
purpose_83 = new Map<any, string>([[1, '技术交易(包含转让、许可、作价投资、质押融资等)'], [2, '应用推广(包含市场推广、项目引进等)'], [3, '水平鉴定(包含报奖、人才评价、成果管理、创新规划等)'], [4, '项目管理(包含项目立项、项目监督、项目结题等)']])
modality_93 = new Map<any, string>([[1, '新方法'], [2, '新产品'], [3, '新工艺'], [4, '新材料/配方'], [5, '新设备/工具'], [6, '软件系统'], [7, '新品种'], [8, '其他']])
res_source_94 = new Map<any, string>([[1, '纵向'], [2, '横向'], [3, '其他']])
source_unit_nature_96 = new Map<any, string>([[1, '高校'], [2, '科研机构'], [3, '其他事业单位'], [4, '社会组织'], [5, '国企'], [6, '名企'], [7, '外企'], [8, '混合所有制企业'], [9, '其他']])
type_98 = new Map<any, string>([[1, '应用技术'], [2, '基础理论'], [3, '软课题研究'], [4, '教育教学'], [5, '创新能力与条件建设'], [6, '技术转移与推广'], [7, '文学艺术实践'], [8, '其它']])
finsh_way_131 = new Map<any, string>([[1, '独立开发'], [2, '委外开发'], [3, '外部购买']])
is_core_132 = new Map<any, string>([[0, '否'], [1, '是']])
view_sort_133 = new Map<any, string>([[1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5']])
result_form_134 = new Map<any, string>([[1, '服务业']])
before_maturity_135 = new Map<any, string>([])
after_maturity_136 = new Map<any, string>([])
maturity_151 = new Map<any, string>([[0, '0'], [1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5'], [6, '6'], [7, '7'], [8, '8'], [9, '9'], [10, '10']])
innovation_153 = new Map<any, string>([[0, '0'], [1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5'], [6, '6'], [7, '7'], [8, '8'], [9, '9'], [10, '10']])
advanced_155 = new Map<any, string>([[0, '0'], [1, '1'], [2, '2'], [3, '3'], [4, '4'], [5, '5'], [6, '6'], [7, '7'], [8, '8'], [9, '9'], [10, '10']])
status_2_327 = new Map<any, string>([[5, '拒绝'], [6, '采纳']])

t_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    name: this.t_search_data.name,
    status: this.t_search_data.status,
    res_source_unit: this.t_search_data.res_source_unit

  }
  Api.http_apply_taskt0(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_all_data = res.data.data
      this.t_total = res.data.total
    }
  })
}
t_edit_init(row) {

  Api.http_apply_tasktget5({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit_data.id = row.id;
      this.t_edit_data.status_1 = res.data.status_1;
      this.t_edit_dialog.open = true;

    }
  })

}
t_edit_cancle() {
  this.t_edit_dialog.open = false;
}
t_edit_ok() {
  this.$refs.ref_t_edit_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit_data.id,
        status_1: this.t_edit_data.status_1

      }
      Api.http_apply_taskt5(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit1_init(row) {
  this.get_expertList(row.id)

  Api.http_apply_tasktget6({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit1_data.id = row.id;
      this.t_edit1_data.user_list = res.data.user_list;
      this.t_edit1_dialog.open = true;

    }
  })

}
t_edit1_cancle() {
  this.t_edit1_dialog.open = false;
}
t_edit1_ok() {
  this.$refs.ref_t_edit1_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit1_data.id,
        user_list: this.t_edit1_data.user_list

      }
      Api.http_apply_taskt6(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit1_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit2_init(row) {
  this.get_getUserList(row.id)

  Api.http_apply_tasktget7({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit2_data.id = row.id;
      this.t_edit2_data.user_list1 = res.data.user_list1;
      this.t_edit2_dialog.open = true;

    }
  })

}
t_edit2_cancle() {
  this.t_edit2_dialog.open = false;
}
t_edit2_ok() {
  this.$refs.ref_t_edit2_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit2_data.id,
        user_list1: this.t_edit2_data.user_list1

      }
      Api.http_apply_taskt7(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit2_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit3_init(row) {
  this.get_getUserList2(row.id)

  Api.http_apply_tasktget8({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit3_data.id = row.id;
      this.t_edit3_data.user_list2 = res.data.user_list2;
      this.t_edit3_dialog.open = true;

    }
  })

}
t_edit3_cancle() {
  this.t_edit3_dialog.open = false;
}
t_edit3_ok() {
  this.$refs.ref_t_edit3_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit3_data.id,
        user_list2: this.t_edit3_data.user_list2

      }
      Api.http_apply_taskt8(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit3_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit4_init(row) {

  Api.http_apply_tasktget9({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit4_data.id = row.id;
      this.t_edit4_data.assess = res.data.assess;
      this.t_edit4_dialog.open = true;

    }
  })

}
t_edit4_cancle() {
  this.t_edit4_dialog.open = false;
}
t_edit4_ok() {
  this.$refs.ref_t_edit4_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit4_data.id,
        assess: this.t_edit4_data.assess

      }
      Api.http_apply_taskt9(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit4_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit5_init(row) {

  Api.http_apply_tasktget10({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit5_data.id = row.id;
      this.t_edit5_data.report = res.data.report;
      this.t_edit5_dialog.open = true;

    }
  })

}
t_edit5_cancle() {
  this.t_edit5_dialog.open = false;
}
t_edit5_ok() {
  this.$refs.ref_t_edit5_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit5_data.id,
        report: this.t_edit5_data.report

      }
      Api.http_apply_taskt10(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit5_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_edit6_init(row) {

  Api.http_apply_tasktget11({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.t_edit6_data.id = row.id;
      this.t_edit6_data.evaluate_info = res.data.evaluate_info;
      this.t_edit6_data.base_info = res.data.base_info;
      this.t_edit6_data.profile_info = res.data.profile_info;
      this.t_edit6_data.wbs_deconstruct = res.data.wbs_deconstruct;
      this.t_edit6_data.techinque_level = res.data.techinque_level;
      this.t_edit6_data.bmk_analysis = res.data.bmk_analysis;
      this.t_edit6_data.promotion = res.data.promotion;
      this.t_edit6_data.economic_benefit = res.data.economic_benefit;
      this.t_edit6_data.social_benefit = res.data.social_benefit;
      this.t_edit6_data.intellectual_property = res.data.intellectual_property;
      this.t_edit6_data.team = res.data.team;
      this.t_edit6_data.risk = res.data.risk;
      this.t_edit6_data.input_and_output = res.data.input_and_output;
      this.t_edit6_data.technology_novelty = res.data.technology_novelty;
      this.t_edit6_data.detect_test = res.data.detect_test;
      this.t_edit6_data.technical_valuation = res.data.technical_valuation;
      this.t_edit6_data.financing_needs = res.data.financing_needs;
      this.t_edit6_data.files = res.data.files;
      this.t_edit6_data.res_menus = res.data.res_menus;
      this.t_edit6_data.opinion_list = res.data.opinion_list;
      this.t_edit6_data.status_2 = res.data.status_2;
      this.t_edit6_dialog.open = true;

    }
  })

}
t_edit6_cancle() {
  this.t_edit6_dialog.open = false;
}
t_edit6_ok() {
  this.$refs.ref_t_edit6_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.t_edit6_data.id,
        opinion_list: this.t_edit6_data.opinion_list,
        status_2: this.t_edit6_data.status_2

      }
      Api.http_apply_taskt11(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.t_edit6_dialog.open = false
          this.t_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
t_init() {
  this.t_get_all_data(0, 10);

}
get_expertList(id = -1) {
  Api.http_apply_tasktexpertList0({ id: id, offset: 0, length: 999 }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.expertList = res.data.data

    }
  })
}
get_getUserList(id = -1) {
  Api.http_apply_tasktgetUserList0({ id: id, offset: 0, length: 999 }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.getUserList = res.data.data

    }
  })
}
get_getUserList2(id = -1) {
  Api.http_apply_tasktgetUserList20({ id: id, offset: 0, length: 999 }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.getUserList2 = res.data.data

    }
  })
}
created() {
  this.t_init();

}

}
